import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '宝船厂_南京宝船厂遗址文化管理有限公司',
    },
  },
  {
    path: '/Content/:id',
    name: 'Content',
    component: () => import('../views/Content.vue'),
    meta: {
      title: '宝船厂',
    },
  },
  {
    path: '/Content-EN/:id',
    name: 'Content-EN',
    component: () => import('../views/Content-EN.vue'),
    meta: {
      title: '宝船厂',
    },
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('../views/Mobile.vue'),
    meta: {
      title: '宝船厂遗址',
    },
  },
  {
    path: '/mobileIndex',
    name: 'MobileIndex',
    component: () => import('../views/MobileIndex.vue'),
    meta: {
      title: '宝船厂',
    },
  },
  {
    path: '/mobileIndexEnglish',
    name: 'MobileIndexEN',
    component: () => import('../views/MobileIndexEN.vue'),
    meta: {
      title: '宝船厂',
    },
  },
]

const router = new VueRouter({
  routes,
})

export default router
