<template>
  <div id="app" :class="{ grayscale: grayscale }">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      grayscale: false
    }
  },
  mounted() {
    this.getDate()
  },
  methods: {
    getDate() {
      let month = new Date().getMonth()
      let date = new Date().getDate()
      if (month == '11' && date == '13') {
        this.grayscale = true
      } else {
        this.grayscale = false
      }
    },
  },
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: "SourceHanSerifCN-Regular";
  user-select: none;
  text-decoration: none;
}

@font-face {
  font-family: "SourceHanSerifCN-Regular";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(../public/font/SourceHanSerifCN-Regular.otf) format("woff2");
}

#app {
  height: 100%;
  width: 100%;
  position: relative;
}

#app.grayscale {
  filter: grayscale(100%);
  height: 100%;
  width: 100%;
  position: relative;
}

html {
  width: 100%;
  height: 100%;
  position: relative;
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
